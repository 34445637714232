import {subscribe, useSnapshot } from "valtio"
import { useEffect, useRef, useState } from "react"
import { AgmodalStore, EntityV2Store, airChargesStore, bookingsStore, cargoStore, chargesStore1, configStore, containerStore, contractsStore, fclChargesStore, loginStore, quoteDataRec } from "../state/CommonState";
import { taskStore } from "./task-store";
import { chargesValidationStore, QuotationValidationStore } from "../state/validation-stores";
import { TableEditaca, TableSelectaca, TableLabel, TableEditChargesaca, EditableInputaca} from "../components/AgComponents"
import { currencyList, chargesData } from "../StaticData"
import Utils from "../util/Utils"
import Taskapi from "./tasks-api";
import { useGlobalState } from "../GlobalState";



export default function Additionalcostapproval(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    useSnapshot(AgmodalStore)
    useSnapshot(bookingsStore)
    useSnapshot(contractsStore)
    const [disableButton, setDisableButton] = useState(true)
    Object.assign(quoteDataRec, bookingsStore.current.contract)
    const [reason, setReason] = useState(''); 
    const inputRef = useRef(null);
    QuotationValidationStore.validate('shipmentType');
    let stype = quoteDataRec.shipmentType
    let key = ''
    if(stype.toUpperCase().indexOf('FCL') > -1){
        key = 'FCL'
    }else if(stype.toUpperCase().indexOf('AIR') > -1){
        key = 'AIR'
    }else{
        key = 'LCL'
    }
    function addlist(){
        if(taskStore.current.chargesList.length==0){
            switch (key) {
                case 'FCL':
                    containerStore.list = bookingsStore.current.contract.containerList
                    fclChargesStore.colNames = {
                        twoSpan: '',
                        twoSpan1: 'Per Container',
                        twoSpan2: '',
                        perShipment: 'Per BL'
                    }
                    fclChargesStore.initialLength = 0
                    fclChargesStore.list = [{
                        id: 1,
                        chargeDescription: '',
                        currency: 'INR'
                    }]
                    taskStore.current.chargesList = fclChargesStore.list
                    break;
                case 'AIR':
                    containerStore.list = bookingsStore.current.contract.containerList
                    airChargesStore.initialLength = 0
                    airChargesStore.list = [
                        {
                            id: 1,
                            chargeDescription: '',
                            currency: 'INR'
                        }
                    ]
                    taskStore.current.chargesList = airChargesStore.list
                    break;
                case 'LCL':
                    chargesStore1.initialLength = 0
                    chargesStore1.list = [
                        {
                            id: 1,
                            chargeDescription: '',
                            currency: 'INR',
                            perCBM: 0,
                            perTon: 0,
                            minimum: 0,
                            maximum: 0
                        }
                    ]
                    taskStore.current.chargesList = chargesStore1.list
                    break;
            }
        }else{
            const getdata = (a) =>{
                let k = []
                if(a!=undefined){
                    for (let i = 0; i < a.length; i++) {
                        a[i]["id"] = i+1
                        k.push(a[i])
                    }
                }
                return k
            }
            let k = getdata(taskStore.current.chargesList)
            console.log("++++",k)
            switch (key) {
                case 'FCL':
                    containerStore.list = bookingsStore.current.contract.containerList
                    fclChargesStore.list = k
                    break;
                case 'AIR':
                    containerStore.list = bookingsStore.current.contract.containerList
                    airChargesStore.list = k
                case 'LCL':
                    chargesStore1.list = k
                    break;
            }
            if(JSON.stringify(k)!=JSON.stringify(taskStore.current.chargesList)){
                taskStore.current.chargesList = k
            }
        }
    }

    function check(){
        if(stage==2){
            let disable = false
            switch (key) {
                case 'FCL':
                    console.log(fclChargesStore.list)
                    if(fclChargesStore.list.length==0) addlist()
                    fclChargesStore.calculateChargesTotals(quoteDataRec.emptycontainerweight)
                    taskStore.current.chargesList.map((e)=>{
                        if(e['chargeDescription'].length==0 || e['currency'].length==0){
                            disable=true
                        }
                        bookingsStore.current.contract.containerList.map((e1)=>{
                            if(e[e1['containerType']+'total'] != undefined){
                                if(e[e1['containerType']+'total']==0){
                                    disable=true
                                }
                            }else{
                                disable=true
                            }
                        })
                    })
                    
                    break;
                case 'AIR':
                    if(airChargesStore.list.length==0) addlist()
                    airChargesStore.calculateChargesTotals()
                    taskStore.current.chargesList.map((e)=>{
                        if(e['chargeDescription'].length==0 || e['currency'].length==0){
                            disable=true
                        }
                        if(e['total']!=undefined){
                            if(e['total']==0){
                                disable=true
                            }
                        }else{
                            disable=true
                        }
                    })
                    
                    break;
                case 'LCL':
                    if(chargesStore1.list.length==0) addlist()
                    chargesStore1.calculateChargesTotals()
                    taskStore.current.chargesList.map((e)=>{
                        
                        if(e['chargeDescription'].length==0 || e['currency'].length==0){
                            disable=true
                        }
                        if(e['total']!=undefined){
                            if(e['total']==0){
                                disable=true
                            }
                        }else{
                            disable=true
                        }
                    })
                    
                    break;
            }
    
            if(taskStore.current.chargesList.length == 0){
                disable = true
            }
            if(!taskStore.current.isValid && !disable){
                taskStore.current.isValid = true
            }
    
            setDisableButton(disable)
        }
        
    }
    let task = taskStore.current
    let stage = task.stage

    useEffect(() => {
        addlist()
        check()
        let unsubscribe = subscribe(taskStore.current, () => {
            check()
        })
        return unsubscribe
    }, [taskStore.current])

    const handleInputChange = (e) => {
        if(configStore.acafoucs!="reason"){
            configStore.acafoucs = "reason"
        }
        setReason(e.target.value);
    };

    const handleBlur = () => {
        taskStore.current.reason = reason;
    };

    return (
        <>
        {
            (stage==1)?<>
            <h1>Please note that this booking has incurred an additional cost of {Utils.N(taskStore.current.total)} on account of {taskStore.current.reason}.</h1>
            <h1>Request your approval for these charges</h1>
            <hr />
            {
                    (stype.toUpperCase().indexOf('LCL') >= 0) ?
                    <><h1>Orginal Cost</h1>
                    <ActChargesView chargelist={taskStore.current.oldchargesList}/>
                    <hr />
                    <h1>Additional Cost</h1>
                    <ActChargesView chargelist={taskStore.current.newchargeList} total = {true} /></> :
                        <></>
                }
                {
                    (stype.toUpperCase().indexOf('AIR') >= 0) ?
                    <><h1>Orginal Cost</h1>
                    <ActChargesAirView chargelist={taskStore.current.oldchargesList}/>
                    <hr />
                    <h1>Additional Cost</h1>
                    <ActChargesAirView chargelist={taskStore.current.newchargeList}  total = {true} /></> :
                        <></>
                }
                {
                    (stype.toUpperCase().indexOf('FCL') >= 0) ?
                       <><h1>Orginal Cost</h1>
                       <ActFCLChargesView chargelist={taskStore.current.oldchargesList} container={bookingsStore.current.contract.containerList} />
                       <hr />
                       <h1>Additional Cost</h1>
                       <ActFCLChargesView chargelist={taskStore.current.newchargeList} container={bookingsStore.current.contract.containerList} total = {true} /></>:
                        <></>
                }
                {
                    (taskStore.current.showModal)?<ActrejectModal />:<></>
                }
                
            <p>
                <button className="button islink"
                    onClick={async (e) => {
                        let usd = configStore.getExchangeRate('USD')
                        if (!usd) usd = 1.0
                        taskStore.current.quoteValue = Utils.R2(taskStore.current.total)
                        taskStore.current.quoteValueUSD = Utils.R2(taskStore.current.total / usd)
                        let booking = bookingsStore.current
                        booking.contract.chargesList = taskStore.current.newchargeList
                        booking.contract.quoteValue =  taskStore.current.quoteValue
                        booking.contract.quoteValueUSD = taskStore.current.quoteValueUSD
                        let ret = await Taskapi.replaceBooking(booking)
                        if (!ret) return
                            taskStore.current.stage = 3
                            taskStore.current.pendingWith = 'AGRAGA'
                            taskStore.setCustomerComplete()
                            await Taskapi.updateTask(taskStore.current)
                            taskStore.triggerActionVisible = false
                            taskStore.myActionVisible = false
                            if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                                AgmodalStore.pageVisible = false
                                AgmodalStore.apiCall = true
                            }
                    }}>Accept</button>
                    <button className="button islink"
                    onClick={async (e) => {
                        taskStore.current.showModal = true
                    }}>Reject</button>
            </p>
            </>:<></>
        }

        {
            (stage==2)?<>
                <br />
            <br />
            <br />
            <div>
                <h1><strong>Reject Reason : </strong>{taskStore.current.rejectReason}</h1>
            </div>
            <hr />
            <div>
                <div class="field is-horizontal">
                    <div class="field-label is-normal" style={{ "textAlign": "left" }}>
                        <label class="label">Reason</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                        <p class="control">
                                <input style={{"width":"50%"}} className={`input is-small ${ (!taskStore.current.isValid && taskStore.current.reason.length==0)  ? "is-danger" : ""} `} type="text" placeholder="Enter Reason" 
                                onClick={()=>{
                                    if(configStore.acafoucs!="reason"){
                                        configStore.acafoucs = "reason"
                                    }
                                }}
                                value={reason}
                                onChange={handleInputChange}
                                onBlur={handleBlur} />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {
                    (stype.toUpperCase().indexOf('LCL') >= 0) ?
                        <ActChargesComp /> :
                        <></>
                }
                {
                    (stype.toUpperCase().indexOf('AIR') >= 0) ?
                        <ActChargesAirComp /> :
                        <></>
                }
                {
                    (stype.toUpperCase().indexOf('FCL') >= 0) ?
                        <ActFCLChargesComp /> :
                        <></>
                }
            </div>
            <br />
            <br />
            <p>
                <button className="button islink"
                    onClick={async (e) => {
                        configStore.acafoucs = ""
                        if(disableButton){
                            taskStore.current.isValid = false
                        }else{
                            let newchargeList = []
                            if(taskStore.current.oldchargesList!=undefined && taskStore.current.oldchargesList.length>0){
                                newchargeList = JSON.parse(JSON.stringify(taskStore.current.oldchargesList))
                            }else{
                                newchargeList = JSON.parse(JSON.stringify(contractsStore.current.chargesList))
                            }
                            let c = taskStore.current.chargesList
                            let booking = bookingsStore.current
                            for (let i = 0; i < c.length; i++) {
                                c[i]['id']=booking.contract.chargesList.length+1
                                newchargeList.push(c[i])
                            }
                            taskStore.current.newchargeList = JSON.parse(JSON.stringify(newchargeList))
                            taskStore.current.stage = 1
                            taskStore.current.pendingWith = 'CUSTOMER'
                            await Taskapi.updateTask(taskStore.current)
                            taskStore.triggerActionVisible = false
                            taskStore.myActionVisible = false
                        }                        
                    }}>Trigger Action</button>
            </p>
            </>:<></>
        }

{
            (stage==3)?<>
            <h1>Please note that this booking has incurred an additional cost of {Utils.N(taskStore.current.total)} on account of {taskStore.current.reason}.</h1>
            <h1>Request your approval for these charges</h1>
            <hr />
            {
                    (stype.toUpperCase().indexOf('LCL') >= 0) ?
                    <><h1>Orginal Cost</h1>
                    <ActChargesView chargelist={taskStore.current.oldchargesList} />
                    <hr />
                    <h1>Additional Cost</h1>
                    <ActChargesView chargelist={taskStore.current.newchargeList} total = {true} /></> :
                        <></>
                }
                {
                    (stype.toUpperCase().indexOf('AIR') >= 0) ?
                    <><h1>Orginal Cost</h1>
                    <ActChargesAirView chargelist={taskStore.current.oldchargesList}  />
                    <hr />
                    <h1>Additional Cost</h1>
                    <ActChargesAirView chargelist={taskStore.current.newchargeList}  total = {true} /></> :
                        <></>
                }
                {
                    (stype.toUpperCase().indexOf('FCL') >= 0) ?
                       <><h1>Orginal Cost</h1>
                       <ActFCLChargesView chargelist={taskStore.current.oldchargesList} container={bookingsStore.current.contract.containerList} />
                       <hr />
                       <h1>Additional Cost</h1>
                       <ActFCLChargesView chargelist={taskStore.current.newchargeList} container={bookingsStore.current.contract.containerList} total = {true} /></>:
                        <></>
                }
                {
                    (taskStore.current.showModal)?<ActrejectModal />:<></>
                }
                
            <p>
                <button className="button islink"
                    onClick={async (e) => {
                        
                        taskStore.current.stage = 4
                        taskStore.current.pendingWith = 'AGRAGA'
                        taskStore.current.status = 'COMPLETED'
                        taskStore.setComplete()
                        await Taskapi.updateTask(taskStore.current)
                        taskStore.triggerActionVisible = false
                        taskStore.myActionVisible = false  
                        if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
                            AgmodalStore.pageVisible = false
                            AgmodalStore.apiCall = true
                        }                                           
                    }}>Acknowledge</button>
            </p>
            </>:<></>
        }
        {
            (stage>=4)?<>
            <h1>Please note that this booking has incurred an additional cost of {Utils.N(taskStore.current.total)} on account of {taskStore.current.reason}.</h1>
            <h1>Request your approval for these charges</h1>
            <hr />
            {
                    (stype.toUpperCase().indexOf('LCL') >= 0) ?
                    <><h1>Orginal Cost</h1>
                    <ActChargesView chargelist={taskStore.current.oldchargesList}/>
                    <hr />
                    <h1>Additional Cost</h1>
                    <ActChargesView chargelist={taskStore.current.newchargeList} total = {true}  /></> :
                        <></>
                }
                {
                    (stype.toUpperCase().indexOf('AIR') >= 0) ?
                    <><h1>Orginal Cost</h1>
                    <ActChargesAirView chargelist={taskStore.current.oldchargesList} />
                    <hr />
                    <h1>Additional Cost</h1>
                    <ActChargesAirView chargelist={taskStore.current.newchargeList}  total = {true} /></> :
                        <></>
                }
                {
                    (stype.toUpperCase().indexOf('FCL') >= 0) ?
                       <><h1>Orginal Cost</h1>
                       <ActFCLChargesView chargelist={taskStore.current.oldchargesList} container={bookingsStore.current.contract.containerList} />
                       <hr />
                       <h1>Additional Cost</h1>
                       <ActFCLChargesView chargelist={taskStore.current.newchargeList} container={bookingsStore.current.contract.containerList} total = {true}  /></> :
                        <></>
                }
                {
                    (taskStore.current.showModal)?<ActrejectModal />:<></>
                }
            </>:<></>
        }
       
        </>
    )
    
}

export function AdditionalcostapprovalTrigger(props) {
    useSnapshot(taskStore)
    useSnapshot(configStore)
    useSnapshot(bookingsStore)
    useSnapshot(contractsStore)
    const { changeCurrentMenu } = useGlobalState();
    const [disableButton, setDisableButton] = useState(true)
    const [oldcharge, setOldcharge] = useState(true)
    Object.assign(quoteDataRec, bookingsStore.current.contract)
    const [reason, setReason] = useState(''); 
    const inputRef = useRef(null);
    QuotationValidationStore.validate('shipmentType');
    let stype = quoteDataRec.shipmentType
    let task = taskStore.current
    let key = ''
    if(stype.toUpperCase().indexOf('FCL') > -1){
        key = 'FCL'
    }else if(stype.toUpperCase().indexOf('AIR') > -1){
        key = 'AIR'
    }else{
        key = 'LCL'
    }
    function addlist(){
        if(taskStore.current.chargesList.length==0){
            switch (key) {
                case 'FCL':
                    containerStore.list = bookingsStore.current.contract.containerList
                    fclChargesStore.colNames = {
                        twoSpan: '',
                        twoSpan1: 'Per Container',
                        twoSpan2: '',
                        perShipment: 'Per BL'
                    }
                    fclChargesStore.initialLength = 0
                    fclChargesStore.list = [{
                        id: 1,
                        chargeDescription: '',
                        currency: 'INR'
                    }]
                    taskStore.current.chargesList = fclChargesStore.list
                    break;
                case 'AIR':
                    containerStore.list = bookingsStore.current.contract.containerList
                    airChargesStore.initialLength = 0
                    airChargesStore.list = [
                        {
                            id: 1,
                            chargeDescription: '',
                            currency: 'INR'
                        }
                    ]
                    taskStore.current.chargesList = airChargesStore.list
                    break;
                case 'LCL':
                    chargesStore1.initialLength = 0
                    chargesStore1.list = [
                        {
                            id: 1,
                            chargeDescription: '',
                            currency: 'INR',
                            perCBM: 0,
                            perTon: 0,
                            minimum: 0,
                            maximum: 0
                        }
                    ]
                    taskStore.current.chargesList = chargesStore1.list
                    break;
            }
        }
    }
    
    function check(){
        let disable = false
        switch (key) {
            case 'FCL':
                console.log(fclChargesStore.list)
                if(fclChargesStore.list.length==0) addlist()
                fclChargesStore.calculateChargesTotals(quoteDataRec.emptycontainerweight)
                taskStore.current.chargesList.map((e)=>{
                    if(e['chargeDescription'].length==0 || e['currency'].length==0){
                        disable=true
                    }
                    bookingsStore.current.contract.containerList.map((e1)=>{
                        if(e[e1['containerType']+'total'] != undefined){
                            if(e[e1['containerType']+'total']==0){
                                disable=true
                            }
                        }else{
                            disable=true
                        }
                    })
                })
                
                break;
            case 'AIR':
                if(airChargesStore.list.length==0) addlist()
                airChargesStore.calculateChargesTotals()
                taskStore.current.chargesList.map((e)=>{
                    if(e['chargeDescription'].length==0 || e['currency'].length==0){
                        disable=true
                    }
                    if(e['total']!=undefined){
                        if(e['total']==0){
                            disable=true
                        }
                    }else{
                        disable=true
                    }
                })
                
                break;
            case 'LCL':
                if(chargesStore1.list.length==0) addlist()
                chargesStore1.calculateChargesTotals()
                taskStore.current.chargesList.map((e)=>{
                    
                    if(e['chargeDescription'].length==0 || e['currency'].length==0){
                        disable=true
                    }
                    if(e['total']!=undefined){
                        if(e['total']==0){
                            disable=true
                        }
                    }else{
                        disable=true
                    }
                })
                
                break;
        }

        if(taskStore.current.chargesList.length == 0){
            disable = true
        }
        if(!taskStore.current.isValid && !disable){
            taskStore.current.isValid = true
        }

        setDisableButton(disable)
    }

    useEffect(() => {
        addlist()
        check()
        let unsubscribe = subscribe(taskStore.current, () => {
                check()
            
        })
        return unsubscribe
    }, [taskStore.current])

    useEffect(()=>{
        if(configStore.acafoucs=="reason"){
            if(inputRef){
                inputRef.current.focus()
            }
        }
    },[inputRef.current])

    const handleInputChange = (e) => {
        if(configStore.acafoucs!="reason"){
            configStore.acafoucs = "reason"
        }
        setReason(e.target.value);
    };

    const handleBlur = () => {
        taskStore.current.reason = reason;
    };

    return (
        <>
            <br />
            <br />
            <br />
            <div>
                <div class="field is-horizontal">
                    <div class="field-label is-normal" style={{ "textAlign": "left" }}>
                        <label class="label">Reason</label>
                    </div>
                    <div class="field-body">
                        <div class="field">
                            <p class="control">
                                <input style={{"width":"50%"}} className={`input is-small ${ (!taskStore.current.isValid && taskStore.current.reason.length==0)  ? "is-danger" : ""} `} type="text" placeholder="Enter Reason" 
                                onClick={()=>{
                                    if(configStore.acafoucs!="reason"){
                                        configStore.acafoucs = "reason"
                                    }
                                }}
                                value={reason}
                                onChange={handleInputChange}
                                onBlur={handleBlur} />
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div>
                {
                    (stype.toUpperCase().indexOf('LCL') >= 0) ?
                        <ActChargesComp /> :
                        <></>
                }
                {
                    (stype.toUpperCase().indexOf('AIR') >= 0) ?
                        <ActChargesAirComp /> :
                        <></>
                }
                {
                    (stype.toUpperCase().indexOf('FCL') >= 0) ?
                        <ActFCLChargesComp /> :
                        <></>
                }
            </div>
            <br />
            <br />
            <p>
                <button className="button islink"
                    onClick={async (e) => {
                        configStore.acafoucs = ""
                        if(disableButton){
                            taskStore.current.isValid = false
                        }else{
                            taskStore.current.oldchargesList = JSON.parse(JSON.stringify(contractsStore.current.chargesList))
                            let newchargeList = contractsStore.current.chargesList
                            let c = taskStore.current.chargesList
                            let booking = bookingsStore.current
                            for (let i = 0; i < c.length; i++) {
                                c[i]['id']=booking.contract.chargesList.length+1
                                newchargeList.push(c[i])
                            }
                            task.newchargeList = JSON.parse(JSON.stringify(newchargeList))
                            taskStore.current.stage = 1
                            taskStore.current.pendingWith = 'CUSTOMER'
                            await Taskapi.saveTask(taskStore.current)
                            taskStore.triggerActionVisible = false
                            taskStore.myActionVisible = false
                            changeCurrentMenu("Bookings")
                           
                        }                        
                    }}>Trigger Action</button>
            </p>
        </>




    )
}

export function ActFCLChargesComp() {
    useSnapshot(taskStore)
    useSnapshot(bookingsStore)
    useSnapshot(EntityV2Store)
    Object.assign(quoteDataRec, bookingsStore.current.contract)
    const imContainerStore = useSnapshot(containerStore)
    const containerNames = imContainerStore.list.map(e => e.containerType)
    const [onaccount, setonaccount] = useState(['On Your Account'])
    let entity = EntityV2Store.list.find((e) => e.entityId === quoteDataRec.entityId)
        if (entity && onaccount.length==1) {
            let sscope = quoteDataRec.shipmentScope
            sscope = sscope.toLowerCase()
            let country = '' + entity.entityCountry
            country = country.toLowerCase()
            let cecisorigin = false
            let cecisdestination = false
            let por = ('' + quoteDataRec.portOfLoading).toLowerCase()
            let pod = ('' + quoteDataRec.finalPlaceOfDelivery).toLowerCase()
            let q = quoteDataRec
            if(entity._id == "TBA" && q.isfromAgragaEnquiry != undefined && q.isfromAgragaEnquiry=='Yes' && q.countryFromEnquiry != undefined){
                country = q.countryFromEnquiry.toLowerCase()
            }
            if(country.includes('hong kong')){
                cecisorigin = por.includes('hong kong')
                cecisdestination = pod.includes('hong kong')
            }else {
                cecisorigin = por.includes(country)
                cecisdestination = pod.includes(country)
            }
            const stau = (a) =>{
                let l = -1
                for (let i = 0; i < a.length; i++) {
                    if(onaccount.indexOf(a[i]) == -1){
                        l = 1
                    }
                }
                if(l==1){
                    setonaccount(a)
                }
            }
            if (cecisorigin && sscope.endsWith('port')) stau(['On Your Account', `Consignee's Account`])
            else if (cecisdestination && sscope.startsWith('port')) stau(['On Your Account', `Shipper's Account`])
            else stau(['On Your Account'])
        }
        console.log('DETECTED CHANGES=', quoteDataRec.entityId, quoteDataRec.portOfReceipt, quoteDataRec.portOfDischarge)
    let [space, setSpace] = useState('')

    if(fclChargesStore.list.length>0){
        let oldchargeskey = []
        Object.entries(fclChargesStore.list[0]).filter(([key, value])=>{
            oldchargeskey.push(key)
        })
        let newchargeskey = []
        oldchargeskey.map(e=>{
            if(e.indexOf("'")==-1){
                newchargeskey.push(e)
            }else{
                containerNames.map(f=>{
                    if(e.indexOf(f)>-1){
                        newchargeskey.push(e)
                    }
                })
            }
        })
        if(oldchargeskey.sort().toString() != newchargeskey.sort().toString()){
            let newchargelist = []
            fclChargesStore.list.map((e)=>{
                var result = Object.fromEntries(newchargeskey.map(col => [col, e[col]]));
                newchargelist.push(result)
            })
            fclChargesStore.list = newchargelist;
        }
        
    }

    let imChargesStore = useSnapshot(fclChargesStore)

    // useEffect(() => {
    //     console.log('ChargesComp useEffect')
    //     taskStore.current.chargesList = fclChargesStore.list
    // }, [])
    const deleteRec = (id) => {
        if (id <= imChargesStore.initialLength) return
        const index = fclChargesStore.list.findIndex(e => e.id === id)
        let records = fclChargesStore.list
        records = [...records]
        records.splice(index, 1)
        for (let i = 0; i < records.length; i++) {
            records[i].id = i + 1
        }
        fclChargesStore.list = records
        taskStore.current.chargesList = fclChargesStore.list
    }
    const Ts = ({ i, name, dataList }) => <TableSelectaca  tabIndex={i} 
        record={fclChargesStore.list[i]} name={name} dataList={dataList} isValid={((fclChargesStore.list[i][name]==undefined || fclChargesStore.list[i][name].length==0) && !taskStore.current.isValid)?false:true}/>
    const Te = ({ i, name }) => {
        const e = fclChargesStore.list[i]
        if (name === 'chargeDescription' && i < fclChargesStore.initialLength)
            return <TableLabel record={fclChargesStore.list[i]} name={name} />
        return (
            <TableEditChargesaca  tabIndex={i}  record={fclChargesStore.list[i]} name={name} isValid={((fclChargesStore.list[i][name]==undefined || fclChargesStore.list[i][name].length==0) && !taskStore.current.isValid)?false:true}/>
        )
    }
    const Tn = ({ i, name, checkname }) => {
        const e = fclChargesStore.list[i]
        let disabled = false
        if (e.disabled) {
            for (let i = 0; i < e.disabled.length; i++) {
                if (name.endsWith(e.disabled[i])) {
                    disabled = true
                    break
                }
            }
        }
        if (disabled)
            return (
                <input disabled='true' style={{ width: '3rem', backgroundColor: '#dddddd', border: 'none' }} />
            )
        else return (
            <TableEditaca tabIndex={i} record={fclChargesStore.list[i]} name={name} type='number'  isValid={((fclChargesStore.list[i][checkname]==undefined || Number(fclChargesStore.list[i][checkname])==0) && !taskStore.current.isValid)?false:true}/>
        )
    }

    let ya_finalTotal = {}
    let ca_finalTotal = {}
    let sa_finalTotal = {}
    containerStore.list.map(c => {
        ya_finalTotal[c.containerType] = 0.0
        ca_finalTotal[c.containerType] = 0.0
        sa_finalTotal[c.containerType] = 0.0
        ya_finalTotal['total'] = 0.0
        ca_finalTotal['total'] = 0.0
        sa_finalTotal['total'] = 0.0
    })
    fclChargesStore.list.map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        let onaccount = ""
        if(e.onaccount==undefined){
            onaccount = "On Your Account"
        }else{
            onaccount = e.onaccount
        }
        containerStore.list.map(c => {
            let totalField = c.containerType + 'total'
            switch (onaccount) {
                case "On Your Account":
                    ya_finalTotal[c.containerType] += parseFloat(e[totalField]) * erate
                    ya_finalTotal['total'] += parseFloat(e[totalField]) * erate
                    break;
                case "Consignee's Account":
                    ca_finalTotal[c.containerType] += parseFloat(e[totalField]) * erate
                    ca_finalTotal['total'] += parseFloat(e[totalField]) * erate
                    break;
                case "Shipper's Account":
                    sa_finalTotal[c.containerType] += parseFloat(e[totalField]) * erate
                    sa_finalTotal['total'] += parseFloat(e[totalField]) * erate
                    break;
            }
        })
    })
    return (
        <>
            <hr />
            <div className="table-container">
                <table className="table is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            {/* <th colSpan='2'>
                                <button className="button is-link is-small is-rounded" onClick={() => {
                                    fclChargesStore.calculateChargesTotals();
                                    setSpace(space === '' ? ' ' : '')
                                }}>Click to Re-Calculate Charges</button>
                            </th> */}
                            <th colSpan='20'>
                                <h3 className="is-size-5 has-text-centered">Charges{space}</h3>
                            </th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            {containerNames.map((e,i) => (
                                <td className="has-text-centered has-text-weight-bold" colSpan={(quoteDataRec.emptycontainerweight!=undefined && quoteDataRec.emptycontainerweight=='yes')?4:3}>{e}({imContainerStore.list[i]['numContainers']})</td>
                            ))}
                            <th></th>
                        </tr>
                        <tr>
                            <th>ID</th>
                            <th>Charge Description</th>
                            <th>On Account</th>
                            <th>Currency</th>
                            {containerNames.map(e => (
                                <>
                                    <th>Per Container</th>
                                    <th>Per BL</th>
                                    {
                                        (quoteDataRec.emptycontainerweight!=undefined && quoteDataRec.emptycontainerweight=='yes')?
                                        <th>Per Ton</th>:""
                                    }
                                    <th style={{ textAlign: "right" }}>Total</th>
                                </>
                            ))}
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            fclChargesStore.list.map((e, i) => {
                                {
                                    return <tr key={e.id}>
                                        <td >{e.id}</td>
                                        <td><Te i={i} name='chargeDescription' /></td>
                                        <td><Ts i={i} name='onaccount' dataList={onaccount} /></td>
                                        <td><Ts i={i} name='currency' dataList={currencyList} /></td>
                                        {containerNames.map(e1 => (
                                            <>
                                                <td><Tn i={i} name={e1 + 'perWM'} checkname={e1 + 'total'} /></td>
                                                <td><Tn i={i} name={e1 + 'perShipment'} checkname={e1 + 'total'} /></td>
                                                {
                                        (quoteDataRec.emptycontainerweight!=undefined && quoteDataRec.emptycontainerweight=='yes')?
                                        <td><Tn i={i} name={e1 + 'perTon'} checkname={e1 + 'total'} /></td>:""
                                    }
                                                <td style={{ textAlign: 'right' }}>
                                                    {
                                                        (e[e1 + 'total'] != 0 && e.currency != '') ?
                                                            Utils.N(e[e1 + 'total'], e.currency) : ''
                                                    }
                                                </td>
                                            </>
                                        ))}
                                        <td>
                                        <div className="field has-addons">

                                            {
                                                (i >= fclChargesStore.initialLength) ?
                                                        <p className="control">
                                                            <button className="button is-danger is-inverted"
                                                                onClick={() => {
                                                                    configStore.acafoucs = ""
                                                                    deleteRec(e.id)
                                                                    setSpace(space === '' ? ' ' : '')
                                                                }}>
                                                                <span className="icon">
                                                                    <i className="fas fa-trash"></i>
                                                                </span>
                                                            </button>
                                                        </p>
                                                   :
                                                    <></>
                                            }
                                            {
                                                i === fclChargesStore.list.length - 1 && (
                                                    <p className="control">
                                                    <button className="button is-success is-inverted"
                                                        onClick={() => {
                                                            configStore.acafoucs = ""
                                                            let obj = fclChargesStore.newRec
                                                            obj = { ...obj }
                                                            fclChargesStore.list.push({ ...obj, id: fclChargesStore.list.length + 1 })
                                                            taskStore.current.chargesList = fclChargesStore.list
                                                            setSpace(space === '' ? ' ' : '')
                                                        }}>
                                                        <span className="icon is-small">
                                                            <i className="fas fa-plus"></i>
                                                        </span>
                                                    </button>
                                                </p>
                                                )
                                            }
                                             </div> 
                                        </td>
                                    </tr>
                                }
                            }
                            )
                        }
                        {
                            (ya_finalTotal['total']!=0)?<tr>
                            <td colSpan={4}></td>
                            {
                                containerStore.list.map(c => {
                                    return (
                                        <>
                                            <td></td>
                                            {
                                        (quoteDataRec.emptycontainerweight!=undefined && quoteDataRec.emptycontainerweight=='yes')?
                                        <><td></td><td>On Your Account</td></>:<td>On Your Account</td>
                                    }
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>
                                                {Utils.N(ya_finalTotal[c.containerType])}
                                                </strong>
                                            </td>
                                            
                                        </>
                                    )
                                })
                            }
                            <td></td>
                        </tr>:""
                        }
                        {
                            (ca_finalTotal['total']!=0)?<tr>
                            <td colSpan={4}></td>
                            {
                                containerStore.list.map(c => {
                                    return (
                                        <>
                                            <td></td>
                                            {
                                        (quoteDataRec.emptycontainerweight!=undefined && quoteDataRec.emptycontainerweight=='yes')?
                                        <><td></td><td>Consignee's Account</td></>:<td>Consignee's Account</td>
                                    }
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>
                                                {Utils.N(ca_finalTotal[c.containerType])}
                                                </strong>
                                            </td>
                                            
                                        </>
                                    )
                                })
                            }
                            <td></td>
                        </tr>:""
                        }
                        {
                            (sa_finalTotal['total']!=0)?<tr>
                            <td colSpan={4}></td>
                            {
                                containerStore.list.map(c => {
                                    return (
                                        <>
                                            <td></td>
                                            {
                                        (quoteDataRec.emptycontainerweight!=undefined && quoteDataRec.emptycontainerweight=='yes')?
                                        <><td></td><td>Shipper's Account</td></>:<td>Shipper's Account</td>
                                    }
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>
                                                {Utils.N(sa_finalTotal[c.containerType])}
                                                </strong>
                                            </td>
                                            
                                        </>
                                    )
                                })
                            }
                            <td></td>
                        </tr>:""
                        }
                        
                    </tbody>
                </table>

            </div>
        </>
    )
}

export function ActChargesComp() {
    useSnapshot(taskStore)
    useSnapshot(cargoStore)
    useSnapshot(bookingsStore)
    useSnapshot(EntityV2Store)
    Object.assign(quoteDataRec, bookingsStore.current.contract)
    console.log('ChargesComp rendering')
    const data = chargesData[quoteDataRec.shipmentType]
    let [space, setSpace] = useState('')
    const [onaccount, setonaccount] = useState(['On Your Account'])
    let entity = EntityV2Store.list.find((e) => e.entityId === quoteDataRec.entityId)
        if (entity && onaccount.length==1) {
            let sscope = quoteDataRec.shipmentScope
            sscope = sscope.toLowerCase()
            let country = '' + entity.entityCountry
            country = country.toLowerCase()
            let cecisorigin = false
            let cecisdestination = false
            let por = ('' + quoteDataRec.portOfLoading).toLowerCase()
            let pod = ('' + quoteDataRec.finalPlaceOfDelivery).toLowerCase()
            let q = quoteDataRec
            if(entity._id == "TBA" && q.isfromAgragaEnquiry != undefined && q.isfromAgragaEnquiry=='Yes' && q.countryFromEnquiry != undefined){
                country = q.countryFromEnquiry.toLowerCase()
            }
            if(country.includes('hong kong')){
                cecisorigin = por.includes('hong kong')
                cecisdestination = pod.includes('hong kong')
            }else {
                cecisorigin = por.includes(country)
                cecisdestination = pod.includes(country)
            }

            const stau = (a) =>{
                let l = -1
                for (let i = 0; i < a.length; i++) {
                    if(onaccount.indexOf(a[i]) == -1){
                        l = 1
                    }
                }
                if(l==1){
                    setonaccount(a)
                }
            }
            if (cecisorigin && sscope.endsWith('port')) stau(['On Your Account', `Consignee's Account`])
            else if (cecisdestination && sscope.startsWith('port')) stau(['On Your Account', `Shipper's Account`])
            else stau(['On Your Account'])
        }
        console.log('DETECTED CHANGES=', quoteDataRec.entityId, quoteDataRec.portOfReceipt, quoteDataRec.portOfDischarge)

    let imChargesStore = useSnapshot(chargesStore1)
    let a = imChargesStore.newRec
    let b = imChargesStore.editRec

    // useEffect(() => {
    //     console.log('ChargesComp1 useEffect')
    //     taskStore.current.chargesList = chargesStore1.list
    // }, [])
    const deleteRec = (id) => {
        if (id <= imChargesStore.initialLength) return
        const index = chargesStore1.list.findIndex(e => e.id === id)
        let records = chargesStore1.list
        records = [...records]
        records.splice(index, 1)
        for (let i = 0; i < records.length; i++) {
            records[i].id = i + 1
        }
        chargesStore1.list = records
        taskStore.current.chargesList = chargesStore1.list
        //console.log('AFTER DELETE=', JSON.stringify(chargesStore1.cargoList))
    }
    const Ts = ({ i, name, dataList }) => <TableSelectaca  tabIndex={i} 
        record={chargesStore1.list[i]} name={name} dataList={dataList} isValid={((chargesStore1.list[i][name]==undefined || chargesStore1.list[i][name].length==0) && !taskStore.current.isValid)?false:true}/>
    const Te = ({ i, name }) => {
        const e = chargesStore1.list[i]
        if (name === 'chargeDescription' && i < chargesStore1.initialLength)
            return <TableLabel record={chargesStore1.list[i]} name={name} />
        return (
            <EditableInputaca  tabIndex={i}  disabled={false} record={chargesStore1.list[i]} name={name} isValid={((chargesStore1.list[i][name]==undefined || chargesStore1.list[i][name].length==0) && !taskStore.current.isValid)?false:true} />
            // <TableEdit record={chargesStore1.list[i]} name={name} isValid={((chargesStore1.list[i][name]==undefined || chargesStore1.list[i][name].length==0) && !taskStore.current.isValid)?false:true}/>
        )
    }
    const Tn = ({ i, name, checkname }) => {
        const e = chargesStore1.list[i]
        let disabled = false
        if (e.disabled) {
            for (let i = 0; i < e.disabled.length; i++) {
                if (name.endsWith(e.disabled[i])) {
                    disabled = true
                    break
                }
            }
        }
        if (disabled)
            return (
                <input disabled='true' style={{ width: '3rem', backgroundColor: '#dddddd', border: 'none' }} />
            )
        else return (
            <TableEditaca tabIndex={i}  record={chargesStore1.list[i]} name={name} type='number'  isValid={(((name !="maximum" && chargesStore1.list[i]['total']!=undefined && Number(chargesStore1.list[i]['total']) == 0) || (chargesStore1.list[i][checkname]==undefined || Number(chargesStore1.list[i][checkname])==0) && !taskStore.current.isValid))?false:true}/>
        )
    }
    const ifNotFCL = () => quoteDataRec.shipmentType.indexOf('FCL') < 0
    const [yafinalTotal,setYafinalTotal] = useState(0.0)
    const [cafinalTotal,setCafinalTotal] = useState(0.0)
    const [safinalTotal,setSafinalTotal] = useState(0.0)
    const calculateFinalTotal =()=>{
        let ya_finalTotal = 0.0
        let ca_finalTotal = 0.0
        let sa_finalTotal = 0.0
        chargesStore1.list.map((e) => {
            let erate = configStore.getExchangeRate(e.currency)
            if (!erate || erate === 0) erate = 1.0
            let onaccount = ""
            if(e.onaccount==undefined){
                onaccount = "On Your Account"
            }else{
                onaccount = e.onaccount
            }
            switch (onaccount) {
                case "On Your Account":
                    ya_finalTotal += e.total * erate
                    break;
                case "Consignee's Account":
                    ca_finalTotal += e.total * erate
                    break;
                case "Shipper's Account":
                    sa_finalTotal += e.total * erate
                    break;
            }
        })
        if(ya_finalTotal!=yafinalTotal){
            setYafinalTotal(ya_finalTotal)
        }
        if(ca_finalTotal!=cafinalTotal){
            setCafinalTotal(ca_finalTotal)
        }
        if(sa_finalTotal!=safinalTotal){
            setSafinalTotal(sa_finalTotal)
        }
    }
    useEffect(()=>{
        calculateFinalTotal()
    },[JSON.stringify(chargesStore1.list),JSON.stringify(taskStore.current)])
    
    return (
        <>
            <hr />
            <div className="table-container" onClick={(e)=> e.stopPropagation()}>
                <table className="table is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            {/* <th  colSpan='2'>
                                <button className="button is-link is-small is-rounded" onClick={() => {
                                    chargesStore1.calculateChargesTotals()
                                    setSpace(space === '' ? ' ' : '')
                                }}>Click to Re-Calculate Charges</button>
                            </th> */}
                            <th colSpan='14' style={{textAlign:"center"}}>
                                <h3 className="is-size-5 ">Charges{space}</h3>
                            </th>
                        </tr>
                        {ifNotFCL()?
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th colSpan='4' className="has-text-centered" style={{backgroundColor: '#efefef'}}>Per Unit</th>
                            <th></th>
                            <th ></th>
                            <th></th>
                        </tr> : <></>
                        }
                        <tr>
                            <th style={{width: '5rem'}}>ID</th>
                            <th style={{width: '20rem'}}>Charge Description</th>
                            <th>On Account</th>
                            <th>Currency</th>
                            <th>Per CBM</th>
                            <th>Per Ton</th>
                            <th>Minimum</th>
                            <th>Maximum</th>
                            <th>Per shipment</th>
                            <th  style={{textAlign: 'right'}}>Total</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            chargesStore1.list.map((e, i) => {
                                {
                                    return <tr key={e.id}>
                                        <td >{e.id}</td>
                                        <td><Te i={i} name='chargeDescription' /></td>
                                        <td><Ts i={i} name='onaccount' dataList={onaccount} /></td>
                                        <td><Ts i={i} name='currency'  dataList={configStore.getCurrencyList()} /></td>
                                        <td><Tn i={i} name='perCBM' /></td>
                                        <td><Tn i={i} name='perTon' /></td>
                                        <td><Tn i={i} name='minimum' /></td>
                                        <td><Tn i={i} name='maximum' /></td>
                                        <td><Tn i={i} name='perShipment' /></td>
                                        <td style={{textAlign: 'right'}}>{
                                            (e.total != 0 && e.currency != '')?Utils.N(e.total, e.currency):''
                                        }</td>
                                        <td>
                                          
                                                    <div className="field has-addons">
                                                          {
                                                (i >= chargesStore1.initialLength)&&  (<p className="control">
                                                            <button className="button is-danger is-inverted"
                                                                onClick={() => {
                                                                    configStore.acafoucs = ""
                                                                    deleteRec(e.id)
                                                                    setSpace(space === '' ? ' ' : '')
                                                                }}>
                                                                <span className="icon">
                                                                    <i className="fas fa-trash"></i>
                                                                </span>
                                                            </button>
                                                        </p>)}
                                                        {
                                                            i == chargesStore1.list.length - 1 && (<p className="control">
                                                            <button className="button is-success is-inverted"
                                                                onClick={() => {
                                                                    configStore.acafoucs = ""
                                                                    let obj = chargesStore1.newRec
                                                                    obj = { ...obj }
                                                                    chargesStore1.list.push({ ...obj, id: chargesStore1.list.length + 1 })
                                                                    taskStore.current.chargesList = chargesStore1.list
                                                                    setSpace(space === '' ? ' ' : '')
                                                                }}>
                                                                <span className="icon is-small">
                                                                    <i className="fas fa-plus"></i>
                                                                </span>
                                                            </button>
                                                        </p>)
                                                        }
                                                    </div> 
                                        </td>
                                    </tr>
                                }
                            }
                            )
                        }
                        {
                            (yafinalTotal!=0)?<tr>
                            <td colSpan={9} style={{textAlign:"right"}}>
                                On Your Account
                            </td>
                            <td><strong>{Utils.N(yafinalTotal)}</strong></td>
                            <td></td>
                        </tr>:""
                        }
                        {
                            (cafinalTotal!=0)?<tr>
                            <td colSpan={9} style={{textAlign:"right"}}>
                                Consignee's Account
                            </td>
                            <td><strong>{Utils.N(cafinalTotal)}</strong></td>
                            <td></td>
                        </tr>:""
                        }
                        {
                            (safinalTotal!=0)?<tr>
                            <td colSpan={9} style={{textAlign:"right"}}>
                                Shipper's Account
                            </td>
                            <td><strong>{Utils.N(safinalTotal)}</strong></td>
                            <td></td>
                        </tr>:""
                        }
                        
                    </tbody>
                </table>
            </div>
        </>
    )
}

export function ActChargesAirComp () {
    useSnapshot(taskStore)
    useSnapshot(bookingsStore)
    useSnapshot(EntityV2Store)
    Object.assign(quoteDataRec, bookingsStore.current.contract)
    const [onaccount, setonaccount] = useState(['On Your Account'])
    let entity = EntityV2Store.list.find((e) => e.entityId === quoteDataRec.entityId)
        if (entity && onaccount.length==1) {
            let sscope = quoteDataRec.shipmentScope
            sscope = sscope.toLowerCase()
            let country = '' + entity.entityCountry
            country = country.toLowerCase()
            let cecisorigin = false
            let cecisdestination = false
            let por = ('' + quoteDataRec.portOfLoading).toLowerCase()
            let pod = ('' + quoteDataRec.finalPlaceOfDelivery).toLowerCase()
            let q = quoteDataRec
            if(entity._id == "TBA" && q.isfromAgragaEnquiry != undefined && q.isfromAgragaEnquiry=='Yes' && q.countryFromEnquiry != undefined){
                country = q.countryFromEnquiry.toLowerCase()
            }
            if(country.includes('hong kong')){
                cecisorigin = por.includes('hong kong')
                cecisdestination = pod.includes('hong kong')
            }else {
                cecisorigin = por.includes(country)
                cecisdestination = pod.includes(country)
            }

            const stau = (a) =>{
                let l = -1
                for (let i = 0; i < a.length; i++) {
                    if(onaccount.indexOf(a[i]) == -1){
                        l = 1
                    }
                }
                if(l==1){
                    setonaccount(a)
                }
            }
            if (cecisorigin && sscope.endsWith('port')) stau(['On Your Account', `Consignee's Account`])
            else if (cecisdestination && sscope.startsWith('port')) stau(['On Your Account', `Shipper's Account`])
            else stau(['On Your Account'])
        }
        console.log('DETECTED CHANGES=', quoteDataRec.entityId, quoteDataRec.portOfReceipt, quoteDataRec.portOfDischarge)
    console.log('ChargesComp rendering')
    const data = chargesData[quoteDataRec.shipmentType]
    let [space, setSpace] = useState('')

    let imChargesStore = useSnapshot(airChargesStore)


    // useEffect(() => {
    //     console.log('ChargesComp useEffect')
    //     taskStore.current.chargesList = airChargesStore.list
    // }, [])
    const deleteRec = (id) => {
        if (id <= imChargesStore.initialLength) return
        const index = airChargesStore.list.findIndex(e => e.id === id)
        let records = airChargesStore.list
        records = [...records]
        records.splice(index, 1)
        for (let i = 0; i < records.length; i++) {
            records[i].id = i + 1
        }
        airChargesStore.list = records
        taskStore.current.chargesList = airChargesStore.list
        //console.log('AFTER DELETE=', JSON.stringify(airChargesStore.cargoList))
    }
    const Ts = ({ i, name, dataList }) => <TableSelectaca  tabIndex={i} 
        record={airChargesStore.list[i]} name={name} dataList={dataList} isValid={((airChargesStore.list[i][name]==undefined || airChargesStore.list[i][name].length==0) && !taskStore.current.isValid)?false:true}/>
    const Te = ({ i, name }) => {
        const e = airChargesStore.list[i]
        if (name === 'chargeDescription' && i < airChargesStore.initialLength)
            return <TableLabel record={airChargesStore.list[i]} name={name} />
        return (
            <TableEditaca tabIndex={i}  record={airChargesStore.list[i]} name={name} isValid={((airChargesStore.list[i][name]==undefined || airChargesStore.list[i][name].length==0) && !taskStore.current.isValid)?false:true}/>
        )
    }
    const Tn = ({ i, name, checkname }) => {
        const e = airChargesStore.list[i]
        let disabled = false
        if (e.disabled) {
            for (let i = 0; i < e.disabled.length; i++) {
                if (name.endsWith(e.disabled[i])) {
                    disabled = true
                    break
                }
            }
        }
        if (disabled)
            return (
                <input disabled='true' style={{ width: '3rem', backgroundColor: '#dddddd', border: 'none' }} />
            )
        else return (
            <TableEditaca tabIndex={i} record={airChargesStore.list[i]} name={name} type='number'  isValid={((airChargesStore.list[i][checkname]==undefined || Number(airChargesStore.list[i][checkname])==0) && !taskStore.current.isValid)?false:true}/>
        )
    }
 
    const ifNotFCL = () => quoteDataRec.shipmentType.indexOf('FCL') < 0

    let ya_finalTotal = 0.0
    let ca_finalTotal = 0.0
    let sa_finalTotal = 0.0
    airChargesStore.list.map((e) => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        let onaccount = ""
        if(e.onaccount==undefined){
            onaccount = "On Your Account"
        }else{
            onaccount = e.onaccount
        }
        switch (onaccount) {
            case "On Your Account":
                ya_finalTotal += e.total * erate
                break;
            case "Consignee's Account":
                ca_finalTotal += e.total * erate
                break;
            case "Shipper's Account":
                sa_finalTotal += e.total * erate
                break;
        }
    })
    return (
        <>
            <hr />
            <div className="table-container">
                <table className="table is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                        {/* <th  colSpan='2'>
                                <button className="button is-link is-small is-rounded" onClick={() => {
                                    airChargesStore.calculateChargesTotals()
                                    setSpace(space === '' ? ' ' : '')
                                }}>Click to Re-Calculate Charges</button>
                            </th> */}
                            <th colSpan='10'>
                                <h3 className="is-size-5 has-text-centered">Charges{space}</h3>
                            </th>
                        </tr>
                        {ifNotFCL()?
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th colSpan='2' className="has-text-centered">Per Unit</th>
                            <th></th>
                            <th></th>
                            <th></th>
                        </tr> : <></>
                        }
                        <tr>
                            <th>ID</th>
                            <th style={{width: '20rem'}}>Charge Description</th>
                            <th>On Account</th>
                            <th>Currency</th>
                            <th>Per Kg</th>
                            <th>Minimum</th>
                            <th>Per AWB</th>
                            <th style={{textAlign: 'right'}}>Total</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            airChargesStore.list.map((e, i) => {
                                {
                                    return <tr key={e.id}>
                                        <td >{e.id}</td>
                                        <td><Te i={i} name='chargeDescription' /></td>
                                        <td><Ts i={i} name='onaccount' dataList={onaccount} /></td>
                                        <td><Ts i={i} name='currency' dataList={configStore.getCurrencyList()} /></td>
                                        <td><Tn i={i} name='perKg' /></td>
                                        <td><Tn i={i} name='minimum' /></td>
                                        <td><Tn i={i} name='perAWB' /></td>
                                        <td style={{textAlign: 'right'}}>{
                                            (e.total != 0 && e.currency != '')?Utils.N(e.total, e.currency):''
                                        }</td>
                                        <td>
                                        <div className="field has-addons">
                                            {
                                                (i >= airChargesStore.initialLength) ?
                                                        <p className="control">
                                                            <button className="button is-danger is-inverted"
                                                                onClick={() => {
                                                                    configStore.acafoucs = ""
                                                                    deleteRec(e.id)
                                                                    setSpace(space === '' ? ' ' : '')
                                                                }}>
                                                                <span className="icon">
                                                                    <i className="fas fa-trash"></i>
                                                                </span>
                                                            </button>
                                                        </p>
                                                  :
                                                    <></>
                                            }
                                            {
                                                i === airChargesStore.list.length - 1 && 
                                                (
                                                    <p className="control">
                                        <button className="button is-success is-inverted"
                                            onClick={() => {
                                                configStore.acafoucs = ""
                                                let obj = airChargesStore.newRec
                                                obj = { ...obj }
                                                airChargesStore.list.push({ ...obj, id: airChargesStore.list.length + 1 })
                                                taskStore.current.chargesList = airChargesStore.list
                                                setSpace(space === '' ? ' ' : '')
                                            }}>
                                            <span className="icon is-small">
                                                <i className="fas fa-plus"></i>
                                            </span>
                                        </button>
                                    </p>
                                                )
                                            }
                                              </div> 
                                        </td>
                                    </tr>
                                }
                            }
                            )
                        }
                        {
                            (ya_finalTotal!=0)?<tr>
                            <td colSpan={7} style={{textAlign:"right"}}>
                                On Your Account
                            </td>
                            <td><strong>{Utils.N(ya_finalTotal)}</strong></td>
                            <td></td>
                        </tr>:""
                        }
                        {
                            (ca_finalTotal!=0)?<tr>
                            <td colSpan={7} style={{textAlign:"right"}}>
                                Consignee's Account
                            </td>
                            <td><strong>{Utils.N(ca_finalTotal)}</strong></td>
                            <td></td>
                        </tr>:""
                        }
                        {
                            (sa_finalTotal!=0)?<tr>
                            <td colSpan={7} style={{textAlign:"right"}}>
                                Shipper's Account
                            </td>
                            <td><strong>{Utils.N(sa_finalTotal)}</strong></td>
                            <td></td>
                        </tr>:""
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}

export function ActFCLChargesView(props) {
    useSnapshot(taskStore)
    const imContainerStore = props.container
    const containerNames = imContainerStore.map(e => e.containerType)



    let chargelist = props.chargelist

    

    const Tn = ({ i, name }) => {
        const e = chargelist[i]
        let disabled = false
        if (e.disabled) {
            for (let i = 0; i < e.disabled.length; i++) {
                if (name.endsWith(e.disabled[i])) {
                    disabled = true
                    break
                }
            }
        }
        if (disabled)
            return (
                <div style={{ color: '#dddddd', backgroundColor: '#dddddd', border: 'none', width: '5rem' }} >.</div>
            )
        else {
            console.log(name, e.currency, e[name])
            let num = e[name]
            num = isNaN(num) ? 0.0 : Number(num)
            return num != 0 ? <span>{Utils.N(num, e.currency)}</span> : <></>
        }
    }
    const displayRec = (e) => {
        for (let i = 0; i < containerNames.length; i++) {
            const c = containerNames[i]
            const wmKey = c + 'perWM'
            const shipmentKey = c + 'perShipment'
            const totalKey = c + 'total'
            const perWM = parseFloat(e[wmKey])
            const perShipment = parseFloat(e[shipmentKey])
            const total = parseFloat(e[totalKey])
            console.log(perWM, perShipment, total)
            if (perWM != 0 || perShipment != 0 || total != 0) return true
        }
        return false
    }
    let counter = 1

    let ya_finalTotal = {}
    let ca_finalTotal = {}
    let sa_finalTotal = {}
    let grantTotaloftotals = 0.0
    props.container.map(c => {
        ya_finalTotal[c.containerType] = 0.0
        ca_finalTotal[c.containerType] = 0.0
        sa_finalTotal[c.containerType] = 0.0
        ya_finalTotal['total'] = 0.0
        ca_finalTotal['total'] = 0.0
        sa_finalTotal['total'] = 0.0
    })
    chargelist.map(e => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        let onaccount = ""
        if(e.onaccount==undefined){
            onaccount = "On Your Account"
        }else{
            onaccount = e.onaccount
        }
        props.container.map(c => {
            let totalField = c.containerType + 'total'
            let value = e[totalField] * erate
            switch (onaccount) {
                case "On Your Account":
                    ya_finalTotal[c.containerType] += value
                    ya_finalTotal['total'] += value
                    break;
                case "Consignee's Account":
                    ca_finalTotal[c.containerType] += value
                    ca_finalTotal['total'] += value
                    break;
                case "Shipper's Account":
                    sa_finalTotal[c.containerType] += value
                    sa_finalTotal['total'] += value
                    break;
            }
            grantTotaloftotals += value
        })
    })
    let checkPerTon = false;
    chargelist.map(e => {
        props.container.map((c) => {
            if(e[c.containerType+'perTon']!=undefined && (+(e[c.containerType+'perTon'])) !== 0 ){
                checkPerTon = true;
            }
        })
    });
    if((taskStore.current.total==0.0 || taskStore.current.total!=grantTotaloftotals) && props.total==true)taskStore.current.total=grantTotaloftotals

    return (
        <>
            <div className="table-container">
                <table className="table is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            <th colSpan='18'>
                                <h3 className="is-size-5 has-text-centered">Charges</h3>
                            </th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            {containerNames.map(e => (
                                <td className="has-text-centered has-text-weight-bold" colSpan={checkPerTon?4:3}>{e}</td>
                            ))}
                        </tr>
                        <tr>
                            <th>ID</th>
                            <th>Charge Description</th>
                            <th>On Account</th>
                            <th>Currency</th>
                            {containerNames.map(e => (
                                <>
                                    <th style={{ textAlign: 'center' }}>Per Container</th>
                                    <th style={{ textAlign: 'center' }}>Per BL</th>
                                    {
                                            (checkPerTon)?<th style={{ textAlign: 'center' }}>Per Ton</th>:""
                                        }
                                    <th style={{ textAlign: 'right' }}>Total</th>
                                </>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            chargelist.map((e, i) => (
                                displayRec(e) ?
                                    <tr key={e.id}>
                                        <td >{counter++}</td>
                                        <td>{e.chargeDescription}</td>
                                        <td>
                                            {(e.onaccount == undefined || (e.onaccount!=undefined && e.onaccount.trim().length==0))?"On Your Account":e.onaccount}
                                        </td>
                                        <td>{e.currency}</td>
                                        {containerNames.map(e1 => (
                                            <>
                                                <td style={{ textAlign: 'center' }}><Tn i={i} name={e1 + 'perWM'} /></td>
                                                <td style={{ textAlign: 'center' }}><Tn i={i} name={e1 + 'perShipment'} /></td>
                                                {
                                                        (checkPerTon)?<td style={{ textAlign: 'center' }}><Tn i={i} name={e1 + 'perTon'} /></td>:""
                                                    }
                                                <td style={{ textAlign: 'right' }}>
                                                    {Utils.N(e[e1 + 'total'], e.currency)}
                                                </td>
                                            </>
                                        ))}
                                    </tr> :
                                    <></>
                            )
                            )
                        }
                        {
                            (ya_finalTotal['total']!=0)?<tr>
                            <td colSpan={4}></td>
                            {
                                containerStore.list.map(c => {
                                    return (
                                        <>
                                            <td></td>
                                            {
                                        (checkPerTon)?
                                        <><td></td><td>On Your Account</td></>:<td>On Your Account</td>
                                    }
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>
                                                {Utils.N(ya_finalTotal[c.containerType])}
                                                </strong>
                                            </td>
                                            
                                        </>
                                    )
                                })
                            }
                        </tr>:""
                        }
                        {
                            (ca_finalTotal['total']!=0)?<tr>
                            <td colSpan={4}></td>
                            {
                                containerStore.list.map(c => {
                                    return (
                                        <>
                                            <td></td>
                                            {
                                        (checkPerTon)?
                                        <><td></td><td>Consignee's Account</td></>:<td>Consignee's Account</td>
                                    }
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>
                                                {Utils.N(ca_finalTotal[c.containerType])}
                                                </strong>
                                            </td>
                                            
                                        </>
                                    )
                                })
                            }
                        </tr>:""
                        }
                        {
                            (sa_finalTotal['total']!=0)?<tr>
                            <td colSpan={4}></td>
                            {
                                containerStore.list.map(c => {
                                    return (
                                        <>
                                            <td></td>
                                            {
                                        (checkPerTon)?
                                        <><td></td><td>Shipper's Account</td></>:<td>Consignee's Account</td>
                                    }
                                            <td style={{ textAlign: 'right' }}>
                                                <strong>
                                                {Utils.N(sa_finalTotal[c.containerType])}
                                                </strong>
                                            </td>
                                            
                                        </>
                                    )
                                })
                            }
                        </tr>:""
                        }
                        <tr>
                            <td colSpan={(checkPerTon)?4+props.container.length*4:4+props.container.length*3}
                                style={{ textAlign: 'right', fontSize: '1.1rem' }}>
                                Grand Total:
                                <strong>
                                    {Utils.N(grantTotaloftotals)}
                                </strong>
                            </td>
                        </tr>
                        <tr>
                            <td colSpan={(checkPerTon)?4+props.container.length*4:4+props.container.length*3} style={{ textAlign: 'right' }}>Taxes & Duties additional, as applicable</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </>
    )
}

export function ActChargesAirView (props) {

    const Tn = ({ i, name }) => {
        const e = props.chargelist[i]
        if (e.disabled && e.disabled.indexOf(name) >= 0)
            return (
                <div style={{color:'#dddddd', backgroundColor: '#dddddd', border: 'none', width: '5rem' }} >.</div>
            )
        else {
            console.log(name, e.currency, e[name])
            let num = e[name]
            num = isNaN(num)?0.0:Number(num)
            return num!=0?<span>{Utils.N(num, e.currency)}</span>:<></>
        }
    }
    const displayRec = (e) => {
        const perKg = parseFloat(e.perKg)
        const perAWB = parseFloat(e.perAWB)
        const total = parseFloat(e.total)
        console.log(perKg, perAWB, total)
        if (perKg != 0 || perAWB != 0 || total != 0) return true
        return false
    }
    let counter = 1
    let finalTotal = 0.0
    let ya_finalTotal = 0.0
    let ca_finalTotal = 0.0
    let sa_finalTotal = 0.0
    props.chargelist.map((e) => {
        let erate = configStore.getExchangeRate(e.currency)
        if (!erate || erate === 0) erate = 1.0
        finalTotal += e.total * erate
        let onaccount = ""
        if(e.onaccount==undefined){
            onaccount = "On Your Account"
        }else{
            onaccount = e.onaccount
        }
        switch (onaccount) {
            case "On Your Account":
                ya_finalTotal += e.total * erate
                break;
            case "Consignee's Account":
                ca_finalTotal += e.total * erate
                break;
            case "Shipper's Account":
                sa_finalTotal += e.total * erate
                break;
        }
    })
    if((taskStore.current.total==0.0 || taskStore.current.total!=finalTotal) && props.total==true)taskStore.current.total=finalTotal
    return (
        <>
            <div className="table-container">
                <table className="table is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            <th colSpan='8'>
                                <h3 className="is-size-5 has-text-centered">Charges</h3>
                            </th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th colSpan='2' className="has-text-centered">Per Unit</th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr>
                            <th>ID</th>
                            <th style={{width: '20rem'}}>Charge Description</th>
                            <th>On Account</th>
                            <th>Currency</th>
                            <th style={{textAlign:'center'}}>Per Kg</th>
                            <th style={{textAlign:'center'}}>Minimum</th>
                            <th style={{textAlign:'center'}}>Per AWB</th>
                            <th style={{textAlign:'right'}}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.chargelist.map((e, i) => {
                                {
                                    return (displayRec(e)) ? 
                                    <tr key={e.id}>
                                        <td >{counter++}</td>
                                        <td>{e.chargeDescription}</td>
                                        <td>
                                            {(e.onaccount == undefined || (e.onaccount!=undefined && e.onaccount.trim().length==0))?"On Your Account":e.onaccount}
                                        </td>
                                        <td>{e.currency}</td>
                                        <td  style={{textAlign:'center'}}><Tn i={i} name='perKg' /></td>
                                        <td style={{textAlign:'center'}}><Tn i={i} name='minimum' /></td>
                                        <td style={{textAlign:'center'}}><Tn i={i} name='perAWB' /></td>
                                        <td style={{textAlign:'right'}}>{Utils.N(e.total, e.currency)}</td>
                                    </tr> : <></>
                                }
                            }
                            )
                        }
                        {
                            (ya_finalTotal!=0)?<tr>
                            <td colSpan={7} style={{textAlign:"right"}}>
                                On Your Account
                            </td>
                            <td><strong>{Utils.N(ya_finalTotal)}</strong></td>
                        </tr>:""
                        }
                        {
                            (ca_finalTotal!=0)?<tr>
                            <td colSpan={7} style={{textAlign:"right"}}>
                                Consignee's Account
                            </td>
                            <td><strong>{Utils.N(ca_finalTotal)}</strong></td>
                        </tr>:""
                        }
                        {
                            (sa_finalTotal!=0)?<tr>
                            <td colSpan={7} style={{textAlign:"right"}}>
                                Shipper's Account
                            </td>
                            <td><strong>{Utils.N(sa_finalTotal)}</strong></td>
                        </tr>:""
                        }
                        <tr>
                            <td colSpan={8} style={{textAlign: 'right'}}>Taxes & Duties additional, as applicable</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </>
    )
}

export function ActChargesView(props) {

    const Tn = ({ i, name }) => {
        const e = props.chargelist[i]
        if (e.disabled && e.disabled.indexOf(name) >= 0)
            return (
                <div style={{ color: '#dddddd', backgroundColor: '#dddddd', border: 'none', width: '5rem' }} >.</div>
            )
        else if (name === 'total') {
            return (
                <span style={{textAlign: 'right'}}>{Utils.N(e[name], e.currency)}</span>
            )
        } else {
            //console.log(name, e.currency, e[name])
            let num = e[name]
            num = isNaN(num)?0.0:Number(num)
            return num!=0?<span>{Utils.N(num, e.currency)}</span>:<></>
        }
    }
    const displayRec = (e) => {
        const perCBM = parseFloat(e.perCBM)
        const perTon = parseFloat(e.perTon)
        const perShipment = parseFloat(e.perShipment)
        const total = parseFloat(e.total)
        console.log(perCBM, perTon, perShipment, total)
        if (perCBM != 0 || perTon != 0 || perShipment != 0 || total != 0) return true
        return false
    }
    let grandTotal = 0.0
    const [yafinalTotal,setYafinalTotal] = useState(0.0)
    const [cafinalTotal,setCafinalTotal] = useState(0.0)
    const [safinalTotal,setSafinalTotal] = useState(0.0)
    const calculateFinalTotal =()=>{
        let ya_finalTotal = 0.0
        let ca_finalTotal = 0.0
        let sa_finalTotal = 0.0
        chargesStore1.list.map((e) => {
            let erate = configStore.getExchangeRate(e.currency)
            if (!erate || erate === 0) erate = 1.0
            grandTotal += parseFloat(e.total ) * erate
            let onaccount = ""
            if(e.onaccount==undefined){
                onaccount = "On Your Account"
            }else{
                onaccount = e.onaccount
            }
            switch (onaccount) {
                case "On Your Account":
                    ya_finalTotal += parseFloat(e.total) * erate
                    break;
                case "Consignee's Account":
                    ca_finalTotal += parseFloat(e.total) * erate
                    break;
                case "Shipper's Account":
                    sa_finalTotal += parseFloat(e.total) * erate
                    break;
            }
        })
        if(ya_finalTotal!=yafinalTotal){
            setYafinalTotal(ya_finalTotal)
        }
        if(ca_finalTotal!=cafinalTotal){
            setCafinalTotal(ca_finalTotal)
        }
        if(sa_finalTotal!=safinalTotal){
            setSafinalTotal(sa_finalTotal)
        }
    }
    useEffect(()=>{
        calculateFinalTotal()
    },[JSON.stringify(chargesStore1.list),JSON.stringify(taskStore.current)])
    let counter = 1
    if((taskStore.current.total==0.0 || taskStore.current.total!=grandTotal) && props.total==true)taskStore.current.total=grandTotal
    return (
        <>
            <div className="table-container">
                <table className="table is-bordered is-striped is-narrow is-hoverable ">
                    <thead>
                        <tr>
                            <th colSpan='11'>
                                <h3 className="is-size-5 has-text-centered">Charges</h3>
                            </th>
                        </tr>
                        <tr>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th></th>
                            <th colSpan='4' className="has-text-centered">Per Unit</th>
                            <th></th>
                            <th></th>
                        </tr>
                        <tr>
                            <th>ID</th>
                            <th style={{ width: '20rem' }}>Charge Description</th>
                            <th>On Account</th>
                            <th>Currency</th>
                            <th style={{textAlign:'center'}}>Per CBM</th>
                            <th style={{textAlign:'center'}}>Per Ton</th>
                            <th style={{textAlign:'center'}}>Minimum</th>
                            <th style={{textAlign:'center'}}>Maximum</th>
                            <th style={{textAlign:'center'}}>Per shipment</th>
                            <th style={{textAlign: 'right'}}>Total</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            props.chargelist.map((e, i) => {
                                {
                                    return (displayRec(e)) ?
                                        <tr key={e.id}>
                                            <td >{counter++}</td>
                                            <td>{e.chargeDescription}</td>
                                            <td>
                                            {(e.onaccount == undefined || (e.onaccount!=undefined && e.onaccount.trim().length==0))?"On Your Account":e.onaccount}
                                        </td>
                                            <td>{e.currency}</td>
                                            <td style={{textAlign:'center'}}><Tn i={i} name='perCBM' /></td>
                                            <td style={{textAlign:'enter'}}><Tn i={i} name='perTon' /></td>
                                            <td style={{textAlign:'center'}}><Tn i={i} name='minimum' /></td>
                                            <td style={{textAlign:'center'}}><Tn i={i} name='maximum' /></td>
                                            <td style={{textAlign:'center'}}><Tn i={i} name='perShipment' /></td>
                                            <td style={{textAlign: 'right'}}><Tn i={i} name='total' /></td>
                                        </tr> : <></>
                                }
                            }
                            )
                        }
                        {
                            (yafinalTotal!=0)?<tr>
                            <td colSpan={9} style={{textAlign:"right"}}>
                                On Your Account
                            </td>
                            <td style={{textAlign:"right"}}><strong>{Utils.N(yafinalTotal)}</strong></td>
                        </tr>:""
                        }
                        {
                            (cafinalTotal!=0)?<tr>
                            <td colSpan={9} style={{textAlign:"right"}}>
                                Consignee's Account
                            </td>
                            <td style={{textAlign:"right"}}><strong>{Utils.N(cafinalTotal)}</strong></td>
                        </tr>:""
                        }
                        {
                            (safinalTotal!=0)?<tr>
                            <td colSpan={9} style={{textAlign:"right"}}>
                                Shipper's Account
                            </td>
                            <td style={{textAlign:"right"}}><strong>{Utils.N(safinalTotal)}</strong></td>
                        </tr>:""
                        }
                        {/* <tr>
                            <td colSpan={9}></td>
                            <td style={{textAlign: 'right'}}><strong>{Utils.N(
                                props.chargelist.reduce((pVal,e) => {
                                    let erate = configStore.getExchangeRate(e.currency)
                                    if (!erate || erate === 0) erate = 1.0
                                    pVal += parseFloat(e.total ) * erate;
                                    return pVal;
                                } , 0)
                            )}</strong></td>
                        </tr> */}
                        <tr>
                            <td colSpan={10} style={{textAlign: 'right'}}>Taxes & Duties additional, as applicable</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </>
    )
}

export function ActrejectModal() {
    const [reason, setReason] = useState("");
    useSnapshot(taskStore)
    let modalClass = 'modal'
    if (taskStore.current.showModal) modalClass += ' is-active'
    const submit = async () => {
        taskStore.current.showModal = false
        taskStore.current.rejectReason = reason
        taskStore.current.stage = 2
        taskStore.current.pendingWith = 'AGRAGA'
        await Taskapi.updateTask(taskStore.current)
        taskStore.triggerActionVisible = false
        taskStore.myActionVisible = false
        if(AgmodalStore.modalPage === "TaskContainer" || loginStore.isClient()){
            AgmodalStore.pageVisible = false
            AgmodalStore.apiCall = true
        }
    }
    return (
        <div className={modalClass} onClick={() => { taskStore.current.showModal = false }}>
            <div className="modal-background" ></div>
            <div className="modal-card" onClick={(e) => { e.stopPropagation() }} style={{ borderRadius: '10px' }} >
                <section className="modal-card-body" >

                <h2 style={{ color: "red" }}>REJECT REASON</h2>
                                            <br></br>
                                            <textarea class="textarea is-info" placeholder="Your Reasons..." rows={5} onChange={(e) => setReason(e.target.value)}></textarea>
                                            <br></br>
                                            <button class="button is-danger" style={{ float: "right" }}
                                            disabled = {reason.length==0}
                                                onClick={submit}
                                            >Submit</button>
                                            <button class="button is-warning" onClick={() => taskStore.current.showModal = false} >cancel</button>
                </section>
            </div>
        </div>
    )
}